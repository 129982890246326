import { ctxPluto } from '@/contexts/TargetContext';
import { fulldate } from '@/utilities/dateUtils';
import { useContext } from 'react';
import TargetMeasures from './TargetMeasures';

import './TrackedTargetDetails.css';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import CopyText from './CopyText';

export default function TrackedTargetDetails() {
  const ctx = useContext(ctxPluto);
  const popupTarget = ctx?.popupTarget;
  if (popupTarget == null || ctx?.mode !== 'target') {
    return;
  }

  function reloadTarget() {
    ctx?.setImmediateLoadTarget(popupTarget);
  }

  function closeDetails() {
    ctx?.setPopupTarget(undefined);
  }
  const target = popupTarget.target;
  return (
    <div className="tracking-details-content">
      <div className="tracking-detail-header">
        <div>
          <img className="target-popup-reload-image" src="/svg/reload.svg" onClick={reloadTarget} />
        </div>
        <div className="target-details-name">{target.name}</div>
        <IconButton onClick={closeDetails} color="primary" className="tracking-details-close">
          <Close />
        </IconButton>
      </div>
      <div className="target-popup-data-container">
        <img src="/svg/location.svg" />
        <div className="target-popup-position">{popupTarget.currentPosition.geocodedAddress}</div>
      </div>
      <CopyText target={popupTarget} />
      <div className="target-popup-data-container-wrapper">
        <span className="target-popup-data-container">
          <img src="/svg/speed.svg" />
          <div className="target-popup-speed">{popupTarget.currentPosition.speed} km/h</div>
        </span>
      </div>
      <div className="target-popup-data-container">
        <img src="/svg/clock.svg" />
        <div className="target-popup-time">{fulldate(popupTarget.currentPosition.timeMicros)}</div>
      </div>
      <TargetMeasures target={popupTarget} />
      <div className="tracking-details-footer"></div>
    </div>
  );
}
