import { ReactElement, useContext, useState, useRef, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { Box, Button, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from '@mui/icons-material';

import { PopupType } from '@/types';
import { popupDefs } from '@/popups';
import { ctxPopup, PopupContext } from '@/contexts/PopupContext';
import { ctxUser } from '@/contexts/UserContext';

import './Home.css';

export function HomeLeftNav({ logout }: { logout: () => void }) {
  const popupCtx = useContext(ctxPopup);
  const userCtx = useContext(ctxUser);
  const ref = useRef<HTMLAnchorElement | null>(null);
  const { t } = useTranslation();
  const client = useQueryClient();
  const [open, setOpen] = useState<boolean>(() => {
    const savedState = localStorage.getItem('drawerState');
    return savedState ? JSON.parse(savedState) : false;
  });
  const [selected, setSelected] = useState(-1);
  const [secondaryMenu, setSecondaryMenu] = useState<ReactElement | undefined>(undefined);

  useEffect(() => {
    localStorage.setItem('drawerState', JSON.stringify(open));
  }, [open]);

  const popups: PopupType[] = useMemo(() => {
    let popups = popupDefs({
      close: () => {
        popupCtx?.setPopupMain(undefined);
        setSelected(-1);
      },
      logout,
      ref,
      client,
      isBottom: false,
    });
    if (userCtx?.isToken()) {
      popups = popups.filter((p) => p.showToken);
    }
    return popups;
  }, [logout, popupCtx, userCtx, client]);

  if (!popupCtx) return;

  const mainPopups = popups.filter((p) => p.mainFunction);
  const secondaryPopups = popups.filter((p) => !p.mainFunction);

  // todo: ami még hiányzik: telefonos nézetre váltásból ne maradjon ott ami ottmarad
  function createIconList(popups: PopupType[], popupCtx: PopupContext, offset: number) {
    return (
      <>
        <List>
          {popups.map((p, i) => (
            <ListItem key={p.title}>
              <ListItemButton
                className={`home-left-nav-list-button${selected == i + offset ? ' Mui-selected' : ''}`}
                onClick={
                  p.element !== undefined
                    ? () => {
                        if (i + offset === 0 || i + offset === 1 || i + offset === 2 || i + offset === 3) {
                          if (selected === i + offset) {
                            setSecondaryMenu(undefined);
                            setSelected(-1);
                          } else {
                            setSecondaryMenu(p.element);
                            setSelected(i + offset);
                          }
                        } else {
                          if (selected === i + offset) {
                            popupCtx.setPopupMain(undefined);
                            setSelected(-1);
                          } else {
                            popupCtx.setPopupMain(p.element);
                            setSelected(i + offset);
                          }
                        }
                      }
                    : () => logout()
                }
              >
                <ListItemIcon className={open ? '' : 'home-left-nav-open-list'}>{p.icon}</ListItemIcon>
                {open && <ListItemText primary={t(p.title)} />}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </>
    );
  }

  return (
    <>
      <Box className={`home-nav ${open ? 'home-left-nav-opened' : 'home-left-nav-closed'}`}>
        <Drawer
          variant="permanent"
          className={`home-left-nav-navigator ${open ? 'home-left-nav-opened' : 'home-left-nav-closed'}`}
          classes={{ paper: open ? 'home-left-nav-opened' : 'home-left-nav-closed' }}
        >
          <div className="home-left-nav-list">
            {createIconList(mainPopups, popupCtx, 0)}
            <div className="home-left-nav-secondary-list">
              {createIconList(secondaryPopups, popupCtx, mainPopups.length)}
            </div>
          </div>
          <div className="home-left-nav-list-close-container">
            <Button onClick={() => setOpen(!open)} className="home-left-nav-list-close">
              {open ? <KeyboardDoubleArrowLeft fontSize="medium" /> : <KeyboardDoubleArrowRight fontSize="medium" />}
            </Button>
          </div>
        </Drawer>
      </Box>
      {secondaryMenu}
    </>
  );
}
