import { Dispatch, SetStateAction, createContext } from 'react';
import { TargetDescription, TargetStatus, Target } from '../tmp';
import { SelectedRoute, Mode } from '@/types';

export type PlutoContext = {
  selectedTargets: TargetDescription[] | undefined;
  setSelectedTargets: Dispatch<SetStateAction<TargetDescription[] | undefined>>;
  selectedTargetStatus: Map<number, TargetStatus>;
  setSelectedTargetStatus: Dispatch<SetStateAction<Map<number, TargetStatus>>>;
  selectedRoutes: SelectedRoute[];
  setSelectedRoutes: Dispatch<SetStateAction<SelectedRoute[]>>; // FIXME
  mode: Mode;
  setMode: Dispatch<SetStateAction<Mode>>;
  searchText: string | undefined;
  setSearchText: Dispatch<SetStateAction<string | undefined>>;

  // FIXME ez legyen map
  // benne pl allapot kulon ??
  fullTargets: Target[] | undefined;
  setFullTargets: Dispatch<SetStateAction<Target[] | undefined>>;

  focusedTarget: Target | undefined;
  setFocusedTarget: Dispatch<SetStateAction<Target | undefined>>;

  // ennek a targetnek van nyitva a popupja
  popupTarget: Target | undefined;
  setPopupTarget: Dispatch<SetStateAction<Target | undefined>>;
  immediateLoadTarget: Target | undefined;
  setImmediateLoadTarget: Dispatch<SetStateAction<Target | undefined>>;
};

export const ctxPluto = createContext<PlutoContext | undefined>(undefined);
