import { Target } from '@/tmp';
import {
  calculateDuration,
  getTimeInMicros,
  ofDayInMillis,
  ofHourInMillis,
  timeDurationShort,
} from '@/utilities/timeUtils';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';

export default function TargetMeasures({ target }: { target: Target }) {
  const { t } = useTranslation();

  if (!target.currentPosition.measures || !target.currentPosition.measures.length) {
    return;
  }

  function getMeasureAgeIndicatorClass(currentTimeMicros: number, timestampMicros: number) {
    const baseClass = 'measure-age-indicator';

    if (timestampMicros == -9223372036854776000) {
      return baseClass;
    }
    const measureAgeMillis = (currentTimeMicros - timestampMicros) / 1000;

    if (measureAgeMillis < ofHourInMillis(1)) {
      return baseClass + ' measure-age-very-fresh';
    }
    if (measureAgeMillis < ofDayInMillis(1)) {
      return baseClass + ' measure-age-fresh';
    }
    if (measureAgeMillis < ofDayInMillis(3)) {
      return baseClass + ' measure-age-middle';
    }
    if (measureAgeMillis < ofDayInMillis(7)) {
      return baseClass + ' measure-age-old';
    }
    return baseClass + ' measure-age-very-old';
  }

  const domPurifyOptions = {
    USE_PROFILES: { html: true },
  };
  const currentTimeMicros = getTimeInMicros();
  // lehet h a container is scrollozos es kulon is teszunk ra ??
  return (
    <div className="target-details-measures">
      <TableContainer component={Paper}>
        <Table className="target-popup-table">
          <TableBody>
            {target.currentPosition.measures.map((measure) => (
              <TableRow key={measure.id} className="target-detail-table-row">
                <TableCell className="measure-title-cell" sx={{ color: 'white' }}>
                  {measure.name}
                </TableCell>
                <TableCell className="measure-value-cell" sx={{ color: 'white' }}>
                  <div className={getMeasureAgeIndicatorClass(currentTimeMicros, measure.timestampMicros)}></div>
                  <span
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(measure.value, domPurifyOptions) }}
                  ></span>
                  <div className="measure-value-date">
                    {measure.timestampMicros > 0
                      ? timeDurationShort(t, calculateDuration(measure.timestampMicros, currentTimeMicros), 2)
                      : ' '}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
