import { ChangeEvent, useState, useRef, useContext, KeyboardEvent } from 'react';
import { Close as ClearText, Search as SearchIcon } from '@mui/icons-material';
import { InputBase, styled, alpha } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ctxUser } from '@/contexts/UserContext';
import CSS from 'csstype';
import { driverChatExtraFeature } from '@/tmp/User';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.1),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.black, 0.15),
  },
  marginRight: 0,
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100% !important',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  borderBottom: '2px solid transparent',
  '&:before': {
    content: "''",
    width: '100%',
    background: 'var(--mui-palette-secondary-main)',
    height: '2px',
    position: 'absolute',
    left: '0',
    bottom: '0',
    transform: 'scaleX(0)',
    transformOrigin: 'center',
    transition: 'transform 0.3s ease',
  },
  '&:focus-within:before': {
    transform: 'scaleX(1)',
  },
}));

export function SearchComponent({
  searchText,
  setSearchText,
  autoFocus,
}: {
  searchText: string | undefined;
  setSearchText: (searchText: string) => void;
  autoFocus: boolean;
}) {
  const [searchTextLocal, setSearchTextLocal] = useState<string | undefined>(searchText);
  const timer = useRef<NodeJS.Timeout | undefined>(undefined);
  const userCtx = useContext(ctxUser);
  const [clearTextButtonStyle, setClearTextButtonStyle] = useState<CSS.Properties>({
    position: 'absolute',
    right: '4px',
    top: '7px',
    display: !searchText ? 'none' : 'inline-flex',
  });

  function setSearchTextWrapper(searchText: string) {
    if (searchText.length != 0) {
      setClearTextButtonStyle({
        ...clearTextButtonStyle,
        display: 'inline-flex',
      });
    } else {
      setClearTextButtonStyle({
        ...clearTextButtonStyle,
        display: 'none',
      });
    }
    setSearchTextLocal(searchText);
    clearTimeout(timer.current);
    if (!userCtx?.user.isAdmin) {
      timer.current = setTimeout(() => {
        setSearchText(searchText);
        timer.current = undefined;
      }, 300);
    }
  }
  const { t } = useTranslation();

  function handleClearTextButtonClick() {
    setSearchTextLocal('');
    setSearchText('');
    setClearTextButtonStyle({
      ...clearTextButtonStyle,
      display: 'none',
    });
  }

  function handleKeyDown(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      setSearchText(event.currentTarget.value);
    }
  }

  function getPlaceholder(): string {
    const driverChat = userCtx?.hasExtraFeature(driverChatExtraFeature);
    if (driverChat) {
      return t('filter.driverSearchPlaceholder');
    } else {
      return t('filter.targetSearchPlaceholder');
    }
  }

  return (
    <>
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          autoFocus={autoFocus}
          value={searchTextLocal || ''}
          onChange={(evt: ChangeEvent<HTMLInputElement>) => setSearchTextWrapper(evt.target.value)}
          placeholder={getPlaceholder()}
          onKeyDown={handleKeyDown}
        />
        <ClearText onClick={() => handleClearTextButtonClick()} style={clearTextButtonStyle} />
      </Search>
    </>
  );
}
