export type User = {
  name: string;
  id: number;
  isAdmin: boolean;
  isTokenUser: boolean;
  expiry: number;
  extraFeatures: string[];
};

export type UserAndMessage = User & {
  userMessage?: string;
};

export const driverChatExtraFeature = 'DRIVER_CHAT';
