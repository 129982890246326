import { ctxNotification } from '@/contexts/NotificationCtx';
import { Target } from '@/tmp';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

export default function CopyText({ target }: { target: Target }) {
  const ctxNotify = useContext(ctxNotification);
  const { t } = useTranslation();

  const handleCopy = async () => {
    await navigator.clipboard.writeText(pos(target));
    ctxNotify?.showNotification('info', t('general.copiedClipboard'), undefined, 3);
  };
  function pos(target: Target) {
    if (!target.currentPosition.latitude || !target.currentPosition.longitude) {
      return '';
    }
    return `${target.currentPosition.latitude.toFixed(4)} ${target.currentPosition.longitude.toFixed(4)}`;
  }

  return (
    <>
      <div className="target-popup-data-container">
        <div className="target-popup-coordinates">{pos(target)}</div>
        <img className="target-defails-clipboard-icon" onClick={handleCopy} src="/svg/clipboard.svg" />
      </div>
    </>
  );
}
