/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * OpenAPI definition
 * OpenAPI spec version: v0
 */
import {
  useMutation,
  useQuery,
  useSuspenseQuery
} from '@tanstack/react-query'
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult
} from '@tanstack/react-query'
import * as axios from 'axios';
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  GetMessagesParams,
  GetNewMessagesParams,
  GetRecentParams,
  GetThreadUserParams,
  MessageContentInITrack,
  TerminalMessageInITrack,
  TerminalMessageThreadInITrack,
  TrackedDetailedMessagesInITrack
} from '../../model'



/**
 * Can be used for setting read flag.
 * @summary Message reader API
 */
export const readMessages = (
    readMessagesBody: number[], options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TerminalMessageInITrack[]>> => {
    
    
    return axios.default.put(
      `/api/v1.1/messages/read`,
      readMessagesBody,options
    );
  }



export const getReadMessagesMutationOptions = <TData = Awaited<ReturnType<typeof readMessages>>, TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: number[]}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['readMessages'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof readMessages>>, {data: number[]}> = (props) => {
          const {data} = props ?? {};

          return  readMessages(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: number[]}, TContext>}

    export type ReadMessagesMutationResult = NonNullable<Awaited<ReturnType<typeof readMessages>>>
    export type ReadMessagesMutationBody = number[]
    export type ReadMessagesMutationError = AxiosError<unknown>

    /**
 * @summary Message reader API
 */
export const useReadMessages = <TData = Awaited<ReturnType<typeof readMessages>>, TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: number[]}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {data: number[]},
        TContext
      > => {

      const mutationOptions = getReadMessagesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Can be used for setting read flag.
 * @summary Message reader API
 */
export const readMessagesAsTerminal = (
    readMessagesAsTerminalBody: number[], options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TerminalMessageInITrack[]>> => {
    
    
    return axios.default.put(
      `/api/v1.1/messages/readAsTerminal`,
      readMessagesAsTerminalBody,options
    );
  }



export const getReadMessagesAsTerminalMutationOptions = <TData = Awaited<ReturnType<typeof readMessagesAsTerminal>>, TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: number[]}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['readMessagesAsTerminal'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof readMessagesAsTerminal>>, {data: number[]}> = (props) => {
          const {data} = props ?? {};

          return  readMessagesAsTerminal(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: number[]}, TContext>}

    export type ReadMessagesAsTerminalMutationResult = NonNullable<Awaited<ReturnType<typeof readMessagesAsTerminal>>>
    export type ReadMessagesAsTerminalMutationBody = number[]
    export type ReadMessagesAsTerminalMutationError = AxiosError<unknown>

    /**
 * @summary Message reader API
 */
export const useReadMessagesAsTerminal = <TData = Awaited<ReturnType<typeof readMessagesAsTerminal>>, TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: number[]}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {data: number[]},
        TContext
      > => {

      const mutationOptions = getReadMessagesAsTerminalMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Can be used for sending a message.
 * @summary Message sender API
 */
export const sendMessageAsTerminal = (
    threadId: number,
    sendMessageAsTerminalBody: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TerminalMessageInITrack>> => {
    
    
    return axios.default.post(
      `/api/v1.1/messages/sendAsTerminal/${threadId}`,
      sendMessageAsTerminalBody,options
    );
  }



export const getSendMessageAsTerminalMutationOptions = <TData = Awaited<ReturnType<typeof sendMessageAsTerminal>>, TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{threadId: number;data: string}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['sendMessageAsTerminal'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sendMessageAsTerminal>>, {threadId: number;data: string}> = (props) => {
          const {threadId,data} = props ?? {};

          return  sendMessageAsTerminal(threadId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{threadId: number;data: string}, TContext>}

    export type SendMessageAsTerminalMutationResult = NonNullable<Awaited<ReturnType<typeof sendMessageAsTerminal>>>
    export type SendMessageAsTerminalMutationBody = string
    export type SendMessageAsTerminalMutationError = AxiosError<unknown>

    /**
 * @summary Message sender API
 */
export const useSendMessageAsTerminal = <TData = Awaited<ReturnType<typeof sendMessageAsTerminal>>, TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{threadId: number;data: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {threadId: number;data: string},
        TContext
      > => {

      const mutationOptions = getSendMessageAsTerminalMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Can be used for sending a mesage.
 * @summary Message sender API
 */
export const sendMessage = (
    threadId: number,
    messageContentInITrack: MessageContentInITrack, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TerminalMessageInITrack>> => {
    
    
    return axios.default.post(
      `/api/v1.1/messages/send/${threadId}`,
      messageContentInITrack,options
    );
  }



export const getSendMessageMutationOptions = <TData = Awaited<ReturnType<typeof sendMessage>>, TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{threadId: number;data: MessageContentInITrack}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['sendMessage'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sendMessage>>, {threadId: number;data: MessageContentInITrack}> = (props) => {
          const {threadId,data} = props ?? {};

          return  sendMessage(threadId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{threadId: number;data: MessageContentInITrack}, TContext>}

    export type SendMessageMutationResult = NonNullable<Awaited<ReturnType<typeof sendMessage>>>
    export type SendMessageMutationBody = MessageContentInITrack
    export type SendMessageMutationError = AxiosError<unknown>

    /**
 * @summary Message sender API
 */
export const useSendMessage = <TData = Awaited<ReturnType<typeof sendMessage>>, TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{threadId: number;data: MessageContentInITrack}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {threadId: number;data: MessageContentInITrack},
        TContext
      > => {

      const mutationOptions = getSendMessageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Can be used for getting messages.
 * @summary Get messages in JSON
 */
export const getMessages = (
    params: GetMessagesParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TerminalMessageInITrack[]>> => {
    
    
    return axios.default.get(
      `/api/v1.1/messages`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetMessagesQueryKey = (params: GetMessagesParams,) => {
    return [`/api/v1.1/messages`, ...(params ? [params]: [])] as const;
    }

    
export const getGetMessagesQueryOptions = <TData = Awaited<ReturnType<typeof getMessages>>, TError = AxiosError<unknown>>(params: GetMessagesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMessages>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetMessagesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getMessages>>> = ({ signal }) => getMessages(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getMessages>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetMessagesQueryResult = NonNullable<Awaited<ReturnType<typeof getMessages>>>
export type GetMessagesQueryError = AxiosError<unknown>


export function useGetMessages<TData = Awaited<ReturnType<typeof getMessages>>, TError = AxiosError<unknown>>(
 params: GetMessagesParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMessages>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getMessages>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetMessages<TData = Awaited<ReturnType<typeof getMessages>>, TError = AxiosError<unknown>>(
 params: GetMessagesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMessages>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getMessages>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetMessages<TData = Awaited<ReturnType<typeof getMessages>>, TError = AxiosError<unknown>>(
 params: GetMessagesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMessages>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get messages in JSON
 */

export function useGetMessages<TData = Awaited<ReturnType<typeof getMessages>>, TError = AxiosError<unknown>>(
 params: GetMessagesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMessages>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetMessagesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetMessagesSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getMessages>>, TError = AxiosError<unknown>>(params: GetMessagesParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMessages>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetMessagesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getMessages>>> = ({ signal }) => getMessages(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMessages>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetMessagesSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getMessages>>>
export type GetMessagesSuspenseQueryError = AxiosError<unknown>


export function useGetMessagesSuspense<TData = Awaited<ReturnType<typeof getMessages>>, TError = AxiosError<unknown>>(
 params: GetMessagesParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMessages>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetMessagesSuspense<TData = Awaited<ReturnType<typeof getMessages>>, TError = AxiosError<unknown>>(
 params: GetMessagesParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMessages>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetMessagesSuspense<TData = Awaited<ReturnType<typeof getMessages>>, TError = AxiosError<unknown>>(
 params: GetMessagesParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMessages>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get messages in JSON
 */

export function useGetMessagesSuspense<TData = Awaited<ReturnType<typeof getMessages>>, TError = AxiosError<unknown>>(
 params: GetMessagesParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMessages>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetMessagesSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Can be used for getting one message.
 * @summary Get message in JSON
 */
export const getMessageById = (
    id: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/api/v1.1/messages/${id}`,options
    );
  }


export const getGetMessageByIdQueryKey = (id: number,) => {
    return [`/api/v1.1/messages/${id}`] as const;
    }

    
export const getGetMessageByIdQueryOptions = <TData = Awaited<ReturnType<typeof getMessageById>>, TError = AxiosError<TerminalMessageInITrack>>(id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMessageById>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetMessageByIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getMessageById>>> = ({ signal }) => getMessageById(id, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getMessageById>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetMessageByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getMessageById>>>
export type GetMessageByIdQueryError = AxiosError<TerminalMessageInITrack>


export function useGetMessageById<TData = Awaited<ReturnType<typeof getMessageById>>, TError = AxiosError<TerminalMessageInITrack>>(
 id: number, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMessageById>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getMessageById>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetMessageById<TData = Awaited<ReturnType<typeof getMessageById>>, TError = AxiosError<TerminalMessageInITrack>>(
 id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMessageById>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getMessageById>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetMessageById<TData = Awaited<ReturnType<typeof getMessageById>>, TError = AxiosError<TerminalMessageInITrack>>(
 id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMessageById>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get message in JSON
 */

export function useGetMessageById<TData = Awaited<ReturnType<typeof getMessageById>>, TError = AxiosError<TerminalMessageInITrack>>(
 id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMessageById>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetMessageByIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetMessageByIdSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getMessageById>>, TError = AxiosError<TerminalMessageInITrack>>(id: number, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMessageById>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetMessageByIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getMessageById>>> = ({ signal }) => getMessageById(id, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMessageById>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetMessageByIdSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getMessageById>>>
export type GetMessageByIdSuspenseQueryError = AxiosError<TerminalMessageInITrack>


export function useGetMessageByIdSuspense<TData = Awaited<ReturnType<typeof getMessageById>>, TError = AxiosError<TerminalMessageInITrack>>(
 id: number, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMessageById>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetMessageByIdSuspense<TData = Awaited<ReturnType<typeof getMessageById>>, TError = AxiosError<TerminalMessageInITrack>>(
 id: number, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMessageById>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetMessageByIdSuspense<TData = Awaited<ReturnType<typeof getMessageById>>, TError = AxiosError<TerminalMessageInITrack>>(
 id: number, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMessageById>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get message in JSON
 */

export function useGetMessageByIdSuspense<TData = Awaited<ReturnType<typeof getMessageById>>, TError = AxiosError<TerminalMessageInITrack>>(
 id: number, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMessageById>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetMessageByIdSuspenseQueryOptions(id,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Returns HTTP 200 with multiple detailed messages even if no data is received after fromTime in 30 seconds. To be used in a long-polling manner.
 * @summary Get most latest detailed messages in JSON created after the specified timestamp.
 */
export const getNewMessages = (
    params: GetNewMessagesParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TrackedDetailedMessagesInITrack>> => {
    
    
    return axios.default.get(
      `/api/v1.1/messages/tracking`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetNewMessagesQueryKey = (params: GetNewMessagesParams,) => {
    return [`/api/v1.1/messages/tracking`, ...(params ? [params]: [])] as const;
    }

    
export const getGetNewMessagesQueryOptions = <TData = Awaited<ReturnType<typeof getNewMessages>>, TError = AxiosError<unknown>>(params: GetNewMessagesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getNewMessages>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetNewMessagesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getNewMessages>>> = ({ signal }) => getNewMessages(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getNewMessages>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetNewMessagesQueryResult = NonNullable<Awaited<ReturnType<typeof getNewMessages>>>
export type GetNewMessagesQueryError = AxiosError<unknown>


export function useGetNewMessages<TData = Awaited<ReturnType<typeof getNewMessages>>, TError = AxiosError<unknown>>(
 params: GetNewMessagesParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getNewMessages>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getNewMessages>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetNewMessages<TData = Awaited<ReturnType<typeof getNewMessages>>, TError = AxiosError<unknown>>(
 params: GetNewMessagesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getNewMessages>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getNewMessages>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetNewMessages<TData = Awaited<ReturnType<typeof getNewMessages>>, TError = AxiosError<unknown>>(
 params: GetNewMessagesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getNewMessages>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get most latest detailed messages in JSON created after the specified timestamp.
 */

export function useGetNewMessages<TData = Awaited<ReturnType<typeof getNewMessages>>, TError = AxiosError<unknown>>(
 params: GetNewMessagesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getNewMessages>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetNewMessagesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetNewMessagesSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getNewMessages>>, TError = AxiosError<unknown>>(params: GetNewMessagesParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getNewMessages>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetNewMessagesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getNewMessages>>> = ({ signal }) => getNewMessages(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getNewMessages>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetNewMessagesSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getNewMessages>>>
export type GetNewMessagesSuspenseQueryError = AxiosError<unknown>


export function useGetNewMessagesSuspense<TData = Awaited<ReturnType<typeof getNewMessages>>, TError = AxiosError<unknown>>(
 params: GetNewMessagesParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getNewMessages>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetNewMessagesSuspense<TData = Awaited<ReturnType<typeof getNewMessages>>, TError = AxiosError<unknown>>(
 params: GetNewMessagesParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getNewMessages>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetNewMessagesSuspense<TData = Awaited<ReturnType<typeof getNewMessages>>, TError = AxiosError<unknown>>(
 params: GetNewMessagesParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getNewMessages>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get most latest detailed messages in JSON created after the specified timestamp.
 */

export function useGetNewMessagesSuspense<TData = Awaited<ReturnType<typeof getNewMessages>>, TError = AxiosError<unknown>>(
 params: GetNewMessagesParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getNewMessages>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetNewMessagesSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Can be used for getting message thread by user name.
 * @summary Get message thread by user in JSON
 */
export const getThreadUser = (
    params: GetThreadUserParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TerminalMessageThreadInITrack[]>> => {
    
    
    return axios.default.get(
      `/api/v1.1/messageThreads/threadUser`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetThreadUserQueryKey = (params: GetThreadUserParams,) => {
    return [`/api/v1.1/messageThreads/threadUser`, ...(params ? [params]: [])] as const;
    }

    
export const getGetThreadUserQueryOptions = <TData = Awaited<ReturnType<typeof getThreadUser>>, TError = AxiosError<unknown>>(params: GetThreadUserParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getThreadUser>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetThreadUserQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getThreadUser>>> = ({ signal }) => getThreadUser(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getThreadUser>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetThreadUserQueryResult = NonNullable<Awaited<ReturnType<typeof getThreadUser>>>
export type GetThreadUserQueryError = AxiosError<unknown>


export function useGetThreadUser<TData = Awaited<ReturnType<typeof getThreadUser>>, TError = AxiosError<unknown>>(
 params: GetThreadUserParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getThreadUser>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getThreadUser>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetThreadUser<TData = Awaited<ReturnType<typeof getThreadUser>>, TError = AxiosError<unknown>>(
 params: GetThreadUserParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getThreadUser>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getThreadUser>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetThreadUser<TData = Awaited<ReturnType<typeof getThreadUser>>, TError = AxiosError<unknown>>(
 params: GetThreadUserParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getThreadUser>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get message thread by user in JSON
 */

export function useGetThreadUser<TData = Awaited<ReturnType<typeof getThreadUser>>, TError = AxiosError<unknown>>(
 params: GetThreadUserParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getThreadUser>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetThreadUserQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetThreadUserSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getThreadUser>>, TError = AxiosError<unknown>>(params: GetThreadUserParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getThreadUser>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetThreadUserQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getThreadUser>>> = ({ signal }) => getThreadUser(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getThreadUser>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetThreadUserSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getThreadUser>>>
export type GetThreadUserSuspenseQueryError = AxiosError<unknown>


export function useGetThreadUserSuspense<TData = Awaited<ReturnType<typeof getThreadUser>>, TError = AxiosError<unknown>>(
 params: GetThreadUserParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getThreadUser>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetThreadUserSuspense<TData = Awaited<ReturnType<typeof getThreadUser>>, TError = AxiosError<unknown>>(
 params: GetThreadUserParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getThreadUser>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetThreadUserSuspense<TData = Awaited<ReturnType<typeof getThreadUser>>, TError = AxiosError<unknown>>(
 params: GetThreadUserParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getThreadUser>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get message thread by user in JSON
 */

export function useGetThreadUserSuspense<TData = Awaited<ReturnType<typeof getThreadUser>>, TError = AxiosError<unknown>>(
 params: GetThreadUserParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getThreadUser>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetThreadUserSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Can be used for getting recent message threads.
 * @summary Get recent message threads in JSON
 */
export const getRecent = (
    params: GetRecentParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TerminalMessageThreadInITrack[]>> => {
    
    
    return axios.default.get(
      `/api/v1.1/messageThreads/recent`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetRecentQueryKey = (params: GetRecentParams,) => {
    return [`/api/v1.1/messageThreads/recent`, ...(params ? [params]: [])] as const;
    }

    
export const getGetRecentQueryOptions = <TData = Awaited<ReturnType<typeof getRecent>>, TError = AxiosError<unknown>>(params: GetRecentParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRecent>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRecentQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRecent>>> = ({ signal }) => getRecent(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getRecent>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRecentQueryResult = NonNullable<Awaited<ReturnType<typeof getRecent>>>
export type GetRecentQueryError = AxiosError<unknown>


export function useGetRecent<TData = Awaited<ReturnType<typeof getRecent>>, TError = AxiosError<unknown>>(
 params: GetRecentParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRecent>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getRecent>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetRecent<TData = Awaited<ReturnType<typeof getRecent>>, TError = AxiosError<unknown>>(
 params: GetRecentParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRecent>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getRecent>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetRecent<TData = Awaited<ReturnType<typeof getRecent>>, TError = AxiosError<unknown>>(
 params: GetRecentParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRecent>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get recent message threads in JSON
 */

export function useGetRecent<TData = Awaited<ReturnType<typeof getRecent>>, TError = AxiosError<unknown>>(
 params: GetRecentParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRecent>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetRecentQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetRecentSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getRecent>>, TError = AxiosError<unknown>>(params: GetRecentParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getRecent>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRecentQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRecent>>> = ({ signal }) => getRecent(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getRecent>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRecentSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getRecent>>>
export type GetRecentSuspenseQueryError = AxiosError<unknown>


export function useGetRecentSuspense<TData = Awaited<ReturnType<typeof getRecent>>, TError = AxiosError<unknown>>(
 params: GetRecentParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getRecent>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetRecentSuspense<TData = Awaited<ReturnType<typeof getRecent>>, TError = AxiosError<unknown>>(
 params: GetRecentParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getRecent>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetRecentSuspense<TData = Awaited<ReturnType<typeof getRecent>>, TError = AxiosError<unknown>>(
 params: GetRecentParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getRecent>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get recent message threads in JSON
 */

export function useGetRecentSuspense<TData = Awaited<ReturnType<typeof getRecent>>, TError = AxiosError<unknown>>(
 params: GetRecentParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getRecent>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetRecentSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



