import { useContext, useState, useRef, useMemo, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { BottomNavigation, BottomNavigationAction, Box, Container, useMediaQuery } from '@mui/material';

import { PopupType } from '@/types';
import { popupDefs } from '@/popups';
import { ctxPopup } from '@/contexts/PopupContext';
import { ctxUser } from '@/contexts/UserContext';

import './Home.css';

export function HomeBottomNav({ logout }: { logout: () => void }) {
  const [value, setValue] = useState(-1);
  const popupCtx = useContext(ctxPopup);
  const userCtx = useContext(ctxUser);
  const ref = useRef<HTMLAnchorElement | null>(null);
  const { t } = useTranslation();
  const client = useQueryClient();

  const popups: PopupType[] = useMemo(() => {
    let popups = popupDefs({
      close: () => {
        setValue(-1);
        popupCtx?.setPopupMain(undefined);
      },
      logout,
      ref,
      client,
      isBottom: true,
    });
    if (userCtx?.isToken()) {
      popups = popups.filter((p) => p.showToken);
    }
    return popups;
  }, [logout, popupCtx, userCtx, client]);
  const narrowScreenSize = useMediaQuery('(max-width: 480px)');

  if (!popupCtx) return;

  return (
    <Box className="home-nav">
      <Container maxWidth={false} className="home-bottom-nav-container">
        <BottomNavigation
          showLabels
          value={value}
          className="home-bottom-nav-navigator"
          onChange={(_event: SyntheticEvent, newValue: number) => {
            popupCtx.setPopupMain(popups[newValue].element);
            setValue(newValue);
          }}
        >
          {popups.map((p) =>
            p.ref ? (
              <BottomNavigationAction
                href="#"
                ref={p.ref}
                key={p.title}
                label={narrowScreenSize ? '' : t(p.title)}
                icon={p.icon}
                data-qa-element-id={`bottom-navigation-action-${p.title}`}
                className="home-bottom-nav-action"
              />
            ) : (
              <BottomNavigationAction
                key={p.title}
                label={narrowScreenSize ? '' : t(p.title)}
                icon={p.icon}
                data-qa-element-id={`bottom-navigation-action-${p.title}`}
                className="home-bottom-nav-action"
              />
            )
          )}
        </BottomNavigation>
      </Container>
    </Box>
  );
}
