import { ReactElement, useContext, useEffect, useState } from 'react';

import { ctxPluto } from '@/contexts/TargetContext';
import { Target, TargetDescription, TargetStatus } from '@/tmp';
import { Mode, SelectedRoute } from '@/types';
import { ctxUser } from '@/contexts/UserContext';

// lehetne a neve olyan h ez a PlutoContext megvalositasa....
export default function GlobalStates({ children }: { children: ReactElement }) {
  const ctx = useContext(ctxUser);

  const [selectedTargets, setSelectedTargets] = useState<TargetDescription[] | undefined>(undefined);
  const [selectedTargetStatus, setSelectedTargetStatus] = useState<Map<number, TargetStatus>>(
    new Map<number, TargetStatus>()
  );
  const [selectedRoutes, setSelectedRoutes] = useState<SelectedRoute[]>([]);
  const [mode, setMode] = useState<Mode>('target');
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [fullTargets, setFullTargets] = useState<Target[] | undefined>(undefined);
  const [focusedTarget, setFocusedTarget] = useState<Target | undefined>(undefined);
  const [popupTarget, setPopupTarget] = useState<Target | undefined>(undefined);
  const [immediateLoadTarget, setImmediateLoadTarget] = useState<Target | undefined>(undefined);

  // visszatoltes localstorage-bol
  useEffect(() => {
    const valuesTargets: TargetDescription[] = JSON.parse(localStorage.getItem('selectedTargets') ?? '[]');
    setSelectedTargets(valuesTargets);
    const valuesSelectedTargetStatus = new Map<number, TargetStatus>(valuesTargets.map((t) => [t.id, 'unknown']));
    setSelectedTargetStatus(valuesSelectedTargetStatus); // FIXME nem tuti h ez kell, visszaallitsuk a statust ???
  }, [ctx?.user]);

  // mentesek localstorage-ba
  useEffect(() => {
    const targets = selectedTargets ?? [];
    localStorage.setItem('selectedTargets', JSON.stringify(targets));
  }, [selectedTargets]);

  return (
    <ctxPluto.Provider
      value={{
        selectedTargets,
        setSelectedTargets,
        selectedTargetStatus,
        setSelectedTargetStatus,
        selectedRoutes,
        setSelectedRoutes,
        mode,
        setMode,
        searchText,
        setSearchText,
        fullTargets,
        setFullTargets,
        focusedTarget,
        setFocusedTarget,
        popupTarget,
        setPopupTarget,
        immediateLoadTarget,
        setImmediateLoadTarget,
      }}
    >
      {children}
    </ctxPluto.Provider>
  );
}
